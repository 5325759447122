import { Rating } from "@material-tailwind/react";
import React, { useState, useEffect } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import AddReview from "../../modal/AddReview";
import { getServices, deleteServices } from "../../apiServices/apiServices";
import config from "../../config.json";
import toast from "react-hot-toast";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import { Helmet } from "react-helmet-async";

function ManageCustomerReview() {
  const [isModalOpen, setIsModalOpen] = useState({
    add_review: false,
    update_review: false,
  });

  const [fieldData, setFieldData] = useState({});
  const [customerReviews, setCustomerReviews] = useState([]);
  const access_token = sessionStorage.getItem("khadoom-frontend-admin-token");
  const baseUrl = config.url;

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const data = await getServices("/api/review/list");
      if (data.responseCode === 200) {
        setCustomerReviews(data.responseData);   
      } else {
       setCustomerReviews([])
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDelete = async (review_id) => {
    if (window.confirm("Do you want delete this review?")) {
      try {
        const data = await deleteServices(
          `/api/review/delete?review_id=${review_id}`,
          access_token
        );
        if (data.responseCode === 200) {
          fetchReviews();
          toast.success("Deleted successfully");
        } else {
          toast.error(error);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleUpdateModal = (review_data) => {
    setFieldData(review_data);
    setIsModalOpen({ ...isModalOpen, update_review: true });
  };

  const handleModal = () => {
    setIsModalOpen({ ...isModalOpen, add_review: true });
  };

  return (
    <div className="p-4 font-gotham">
    <Helmet>
        <title>
          Khadom | Manage Customer Reviews
        </title>
      </Helmet>

      <div className="flex justify-between">
        <h2 className="text-2xl text-[#000000] font-semibold mb-4">
          Customer Reviews
        </h2>
        <button
          onClick={handleModal}
          className="bg-[#0000EC] px-5 py-2 rounded-lg m-3 text-white hover:bg-[#d9dc36]"
        >
          Add Review
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {customerReviews.map((review) => (
          <div
            key={review.review_id}
            className="border rounded-2xl flex flex-col p-4 bg-white overflow-hidden relative"
          >
            <div className="absolute top-3 right-3 flex gap-2">
              <MdOutlineModeEditOutline
                className="text-2xl cursor-pointer hover:text-blue-500"
                onClick={() => handleUpdateModal(review)}
              />
              <MdDeleteOutline
                className="text-2xl cursor-pointer hover:text-red-500"
                onClick={() => handleDelete(review.review_id)}
              />
            </div>
            <div className="flex items-center justify-center mt-3">
              <img
                src={`${baseUrl}${review.image}`}
                alt={review.name}
                className="w-24 h-24 rounded-full object-cover"
              />
            </div>
            <div className="flex justify-center mt-3">
              <Rating
                key={review.rating}
                value={Number(review.rating)}
                className="text-yellow-500 flex"
                readonly
              />
            </div>
            <div className="flex-1 ">
              <p className="text-gray-700 text-center">
                {review.description}
              </p>
              <h3 className="text-xl font-semibold mb-2 text-center">
                {review.name}
              </h3>
            </div>
          </div>
        ))}
      </div>

      <AddReview
        open={isModalOpen.add_review}
        onClose={() => setIsModalOpen({ ...isModalOpen, add_review: false })}
        modal={isModalOpen}
        fetchReviews={fetchReviews}
      />
      <AddReview
        open={isModalOpen.update_review}
        onClose={() => setIsModalOpen({ ...isModalOpen, update_review: false })}
        modal={isModalOpen}
        fetchReviews={fetchReviews}
        fieldData={fieldData}
      />
    </div>
  );
}

export default ManageCustomerReview;
