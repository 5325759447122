import React, { useState, useEffect } from "react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { IoClose } from "react-icons/io5";
import { Rating } from "@material-tailwind/react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { postServices, putServices } from "../apiServices/apiServices";
import toast from "react-hot-toast";
import config from "../config.json";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddReview(props) {
  const [image, setImage] = useState(null);
  // const [error, setError] = useState("");
  const [addFieldData, setAddFieldData] = useState({
    name: "",
    description: "",
    rating: 0,
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const access_token = sessionStorage.getItem("khadoom-frontend-admin-token");
  const url = config.url;
  const maxLength = 300;
  const maxFileSize = 2 * 1024 * 1024; // 2MB

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "35px",
    outline: "none",
    borderRadius: "15px",
  };

  useEffect(() => {
    setAddFieldData({
      name: props.fieldData?.name ?? "",
      description: props.fieldData?.description ?? "",
      rating: props.fieldData?.rating ?? 0,
    });
    setImage(props.fieldData?.image ? url + props.fieldData?.image : null);
  }, [props.fieldData]);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/svg+xml",
        "image/webp",
        "image/heif",
        "image/heic",
        "image/avif",
      ];

      if (!allowedTypes.includes(file.type)) {
        toast.error("Unsupported file format");
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("File size exceeds 2MB");
        return;
      }

      setImage(URL.createObjectURL(file));
      setSelectedFiles([file]);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();

    formData.append("name", addFieldData.name);
    formData.append("description", addFieldData.description);
    formData.append("rating", Number(addFieldData.rating));
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("image", selectedFiles[i]);
    }

    try {
      const res = await postServices(`/api/review/add`, formData, access_token);
      setLoading(false);
      if (res.responseCode === 200) {
        toast.success("Review added...");
        resetForm();
        props.onClose();
        props.fetchReviews();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();

    formData.append("name", addFieldData.name);
    formData.append("description", addFieldData.description);
    formData.append("rating", Number(addFieldData.rating));
    if (selectedFiles.length > 0) {
      formData.append("image", selectedFiles[0]);
    }

    try {
      const response = await putServices(
        `/api/review/update?review_id=${props.fieldData?.review_id}`,
        formData,
        access_token
      );
      setLoading(false);
      if (response.responseCode === 200) {
        toast.success("Review updated...");
        props.onClose();
        props.fetchReviews();
      } else {
        toast.error(response.responseMessage);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const resetForm = () => {
    setImage(null);
    setAddFieldData({
      name: "",
      description: "",
      rating: 0,
    });
    setSelectedFiles([]);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className="flex justify-between items-center">
            <p className="font-semibold text-3xl text-black font-gotham">
              {props.modal?.update_review ? "Update Review" : "Add Review"}
            </p>
            <IoClose
              className="cursor-pointer border border-[#f73939] rounded-full absolute right-6 top-8 text-[#f73939]"
              onClick={props.onClose}
            />
          </div>
          <form
            onSubmit={
              props.modal?.update_review ? handleUpdateSubmit : handleAddSubmit
            }
            className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3 review-form"
          >
            <input
              className="w-full text-black font-light bg-[#ECF9FF] px-6 py-3 outline-none font-gotham rounded-full"
              type="text"
              name="full_name"
              placeholder="Full Name *"
              value={addFieldData.name}
              onChange={(e) =>
                setAddFieldData({ ...addFieldData, name: e.target.value })
              }
              required
            />
            <Button
              sx={{
                backgroundColor: "transparent",
                fontFamily: "GothamPro",
                border: "2px",
                borderColor: "#E2E301",
                color: "#8f8f8f",
                ":hover": {
                  color: "white",
                },
              }}
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload Profile Picture *
              <VisuallyHiddenInput
                type="file"
                accept="image/png, image/jpeg, image/jpg, image/svg+xml, image/webp, image/heif, image/heic, image/avif"
                onChange={handleImageChange}
              />
            </Button>
            {image && (
              <div className="mt-3 flex justify-start">
                <img
                  src={image}
                  alt="Profile Preview"
                  className="h-20 w-20 rounded-full object-cover"
                />
              </div>
            )}
            <span className="text-red-600 font-gotham text-center font-light">
              .jpg, .jpeg, .png, .heic, .heif, .svg, .webp, .avif. Maximum file
              size is 2MB for images *
            </span>
            <textarea
              className="w-full px-6 py-3 outline-none bg-[#ECF9FF] font-gotham rounded-xl text-black font-light h-40"
              name="Message"
              placeholder="Message *"
              value={addFieldData.description}
              onChange={(e) =>
                setAddFieldData({
                  ...addFieldData,
                  description: e.target.value,
                })
              }
              maxLength={maxLength}
              required
            />
            <div className="text-right text-[#8f8f8f] font-gotham text-xs">
              {addFieldData.description.length}/{maxLength}
            </div>
            <div className="flex justify-start gap-3">
              <label className="text-lg font-light font-gotham">Rate us</label>
              <Rating
                key={addFieldData.rating}
                className="text-yellow-500 mr-2 flex"
                value={Number(addFieldData.rating)}
                onChange={(value) => {
                  setAddFieldData({ ...addFieldData, rating: value });
                }}
              />
            </div>
            <div className="flex justify-end items-center">
              <button
                type="submit"
                disabled={loading}
                className="rounded-full hover:bg-[#E2E301] px-12 py-2 hover:text-[#1818EC] bg-[#1976d2] text-[#E2E301] tracking-widest font-gotham font-light text-lg outline-none disabled:cursor-not-allowed disabled:bg-gray-600 disabled:text-white"
              >
                {loading ? "Loading..." : "SUBMIT"}
              </button>
            </div>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
}

export default AddReview;
