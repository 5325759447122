import React from "react";
import Logo from "../../../assets/Group 49.png";
import { IoLogoInstagram } from "react-icons/io";
import { SlSocialLinkedin } from "react-icons/sl";
import { RiTiktokLine } from "react-icons/ri";

function Footer() {
  return (
    <div className="relative footer-section bg-gray-100">
      <div className="grid grid-cols-1 md:grid-cols-2 max-w-[1200px] mx-auto py-8 md:py-16 px-4">
        <div>
          <img src={Logo} alt="Khadoom" className="w-auto h-9" />
          <div className="pt-6 md:pt-9">
            <p className="font-gotham text-white font-[400] text-sm md:text-base leading-relaxed">
              I am Khadoom. Whatever language you speak, I can
              <br className="hidden lg:block" /> understand and whatever task
              you need I can get done!
              <br className="hidden lg:block" /> Contact me on WhatsApp for all
              your daily chores
              <br className="hidden lg:block" />
              and leave the rest up to me.
            </p>
            <div className="hidden md:flex items-center gap-3 md:gap-5 pt-4 md:pt-5">
              <IoLogoInstagram
                className="text-lg md:text-[27px] cursor-pointer"
                onClick={() =>
                  window.open("https://www.instagram.com/khadoom.ai/")
                }
              />
              <SlSocialLinkedin className="text-lg md:text-xl cursor-pointer font-bold" />
              <RiTiktokLine
                className="text-lg md:text-[25px] cursor-pointer"
                onClick={() =>
                  window.open("https://www.tiktok.com/@khadoom.ai")
                }
              />
              {/* <img
                src={Facebook}
                alt="Khadoom"
                className="w-4 h-4 md:w-5 md:h-5"
              /> */}
            </div>
          </div>
        </div>

        <div className="pt-8 md:pt-0">
          <div className="flex gap-5 sm:grid sm:grid-cols-2 text-white md:grid-cols-4 uppercase font-gotham text-sm md:text-base">
            <p
              className="text-center font-light hover:font-semibold hover:text-[#0000EC] cursor-pointer transition-all"
              onClick={() => (window.location.href = "#")}
            >
              Home
            </p>
            <p
              className="text-center font-light cursor-pointer hover:font-semibold hover:text-[#0000EC] transition-all"
              onClick={() => (window.location.href = "#about-us")}
            >
              About me
            </p>
            <p
              className="text-center font-light cursor-pointer hover:font-semibold hover:text-[#0000EC] transition-all"
              onClick={() => (window.location.href = "#features")}
            >
              Features
            </p>
            <p
              className="text-center md:text-right font-light cursor-pointer hover:font-semibold hover:text-[#0000EC] transition-all"
              onClick={() => (window.location.href = "#contact-us")}
            >
              Contact us
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 text-white pt-8 md:pt-[85px] items-start sm:items-end h-[65%]">
            <div className="pl-0 sm:pl-8 lg:pl-12">
              <span className=" font-semibold font-gotham">Contact</span>
              <div className="pt-2 flex flex-col gap-2 sm:gap-10 font-gotham font-light">
                <span>Lusail, Qatar</span>
              </div>
            </div>
            <div className="text-left sm:text-right flex flex-col gap-2">
              <span
                className="font-gotham font-light pt-4 sm:pt-0 cursor-pointer"
                onClick={() =>
                  (window.location.href = "mailto:Info@khadoom.ai")
                }
              >
                Info@khadoom.ai
              </span>
              <span
                className="font-gotham font-light pt-2 sm:pt-0 cursor-pointer"
                onClick={() => (window.location.href = "tel:+97474447766")}
              >
                +974 7444 7766
              </span>
            </div>
          </div>
          <div className="md:hidden flex items-center gap-3 md:gap-5 pt-7 md:pt-5">
            <IoLogoInstagram
              className="text-lg md:text-[27px] cursor-pointer"
              onClick={() =>
                window.open("https://www.instagram.com/khadoom.ai/")
              }
            />
            <SlSocialLinkedin className="text-lg md:text-xl cursor-pointer font-bold" />
            <RiTiktokLine
              className="text-lg md:text-[25px] cursor-pointer"
              onClick={() => window.open("https://www.tiktok.com/@khadoom.ai")}
            />
            {/* <img
                src={Facebook}
                alt="Khadoom"
                className="w-4 h-4 md:w-5 md:h-5"
              /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
