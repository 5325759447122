import React from "react";
import HeaderNavbar from "../../components/user/common/HeaderNavbar";
import Banner from "../../components/user/sections/Banner";
import Features from "../../components/user/sections/Features";
import Footer from "../../components/user/common/Footer";
import ContactSec from "../../components/user/sections/ContactSec";

function UserHomePage() {
  return (
    <HeaderNavbar>
      <Banner />
      <Features />
      <ContactSec />
      <Footer />
    </HeaderNavbar>
  );
}

export default UserHomePage;
