import React, { useEffect, useState } from "react";
import AdminSidebar from "../admin/common/AdminSidebar";
import { FaBars } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function AdminLayout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem("khadoom-frontend-admin-token")) {
      navigate("/sign_in");
    }
  }, [collapsed]);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className={`app ${toggled ? "toggled" : ""} bg-zinc-100`}>
      <AdminSidebar
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      />
      <main className="bg-gray-100">
        <div className="p-2">
          <FaBars
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          />
        </div>
        {props.children}
      </main>
    </div>
  );
}

export default AdminLayout;
