import React, { useState, useEffect } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import AddFAQ from "../../modal/AddFAQ";
import { getServices, deleteServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet-async";

function ManageFAQ() {
  const [isModalOpen, setIsModalOpen] = useState({
    add_faq: false,
    update_faq: false,
  });
  const [fieldData, setFieldData] = useState({});
  const [faqs, setFaqs] = useState([]);
  const access_token = sessionStorage.getItem("khadoom-frontend-admin-token");

  useEffect(() => {
    fetchFAQs();
  }, []);

  const fetchFAQs = async () => {
    try {
      const response = await getServices("/api/question/list");
      if (response.responseCode === 200) {
        setFaqs(response.responseData);
      } else {
        setFaqs([]);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const handleModal = () => {
    setIsModalOpen({ ...isModalOpen, add_faq: true });
  };

  const handleUpdateModal = (faq_Data) => {
    setFieldData(faq_Data);
    setIsModalOpen({ ...isModalOpen, update_faq: true });
  };

  const handleDelete = async (question_id) => {
    if (window.confirm("Do you want to delete this question?")) {
      try {
        const data = await deleteServices(
          `/api/question/delete?question_id=${question_id}`,
          access_token
        );
        if (data.responseCode === 200) {
          fetchFAQs();
          toast.success("Deleted successfully");
        } else {
          toast.error(data.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <>
    <Helmet>
        <title>
        Khadoom | Manage FAQ
        </title>
      </Helmet>
      <div className="p-4 font-gotham">
        <div className="flex justify-between">
          <h2 className="text-2xl text-[#000000] font-semibold mb-4">
            Frequently Asked Questions
          </h2>
          <button
            onClick={handleModal}
            className="bg-[#0000EC] px-5 py-2 rounded-lg m-3 text-white hover:bg-[#d9dc36]"
          >
            Add FAQ
          </button>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6">
          {faqs.length>0 && faqs.map((faq) => (
            <div key={faq.question_id} className="faq-item mb-4">
              <div className="flex justify-end gap-5 ">
                <MdOutlineModeEditOutline
                  className="text-xl cursor-pointer"
                  onClick={() => handleUpdateModal(faq)}
                />
                <MdDeleteOutline
                  className="text-2xl cursor-pointer hover:text-red-500"
                  onClick={() => handleDelete(faq.question_id)}
                />
              </div>
              <h2 className="faq-question text-xl mb-2 text-grey-600">
                {faq.question}
              </h2>
              <p className="faq-answer text-slate-500">{faq.answer}</p>
              <hr className="my-4" />
            </div>
          ))}
        </div>
      </div>

      <AddFAQ
        open={isModalOpen.add_faq}
        onClose={() => setIsModalOpen({ ...isModalOpen, add_faq: false })}
        modal={isModalOpen}
        fetchFAQs={fetchFAQs}
      />
      <AddFAQ
        open={isModalOpen.update_faq}
        onClose={() => setIsModalOpen({ ...isModalOpen, update_faq: false })}
        modal={isModalOpen}
        fetchFAQs={fetchFAQs}
        fieldData={fieldData}
      />
    </>
  );
}

export default ManageFAQ;
