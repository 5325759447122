import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UserHomePage from "./pages/user/UserHomePage";
import ManageCustomerReview from "./pages/admin/ManageCustomerReview";
import AdminLayout from "./components/layouts/AdminLayout";
import ManageFAQ from "./pages/admin/ManageFAQ";
import ManageBlog from "./pages/admin/ManageBlog";
import LoginPage from "./auth/LoginPage";
import "./styles.scss";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<UserHomePage />} />
          <Route path="/sign_in" element={<LoginPage />} />
          <Route
            path="/admin/manage_customer_review"
            element={
              <AdminLayout>
                <ManageCustomerReview />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_faq"
            element={
              <AdminLayout>
                <ManageFAQ />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_blog"
            element={
              <AdminLayout>
                <ManageBlog />
              </AdminLayout>
            }
          />
        </Routes>
      </BrowserRouter>

      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
}

export default App;
