import React from "react";
import Logo from "../../../assets/Group 2.png";
import Banner1 from "../../../assets/Image 2.png";
import Banner2 from "../../../assets/Image 4.png";
import Banner3 from "../../../assets/Image 3.png";

function Banner() {
  return (
    <div>
      <div className="w-full overflow-hidden banner-section">
        <div className="flex justify-between w-full pt-5 sm:pt-7 pl-4 sm:pl-10">
          <img src={Logo} alt="Khadoom" className="w-auto h-7 sm:h-9" />
        </div>

        <div className="relative max-w-[1300px] mx-auto">
          <div className="uppercase text-center font-bold font-gotham text-[65px] sm:text-[130px] md:text-[160px] lg:text-[180px] xl:text-[230px] leading-[60px] sm:leading-[110px] md:leading-[130px] lg:leading-[160px] xl:leading-[180px] pt-[80px] sm:pt-[120px] md:pt-[150px] lg:pt-[180px] pb-[130px] sm:pb-[160px] md:pb-[200px] lg:pb-[220px]">
            <p className="text-white">Hi I'm</p>
            {/* <p className="text-[#E2E301]">Khadoom</p> */}
            <p className="text-[#E2E301]">
              <span className="name-letter">K</span>
              <span className="name-letter">h</span>
              <span className="name-letter">a</span>
              <span className="name-letter">d</span>
              <span className="name-letter">o</span>
              <span className="name-letter">o</span>
              <span className="name-letter">m</span>
            </p>
          </div>

          <div>
            {/* <div className="absolute top-[32%] sm:top-[32%] md:top-[30%] left-[18%] sm:left-[14%] xl:left-[15%] -rotate-[19deg] transform -translate-y-1/2 banner1-img"> */}
            <div className="absolute top-[37%] sm:top-[35%] md:top-[37%] left-[10%] sm:left-[7%] md:left-[8%] lg:left-[9%] xl:left-[7%] transform -translate-y-1/2 banner1-img">
              <img
                src={Banner1}
                // className="w-[60px] sm:w-[120px] md:w-[130px] lg:w-[190px] wiggle-img"
                className="w-[100px] sm:w-[200px] md:w-[250px] lg:w-[350px] wiggle-img"
                alt="Banner 1"
              />
            </div>
            <div className="absolute top-[47%] sm:top-[49%] md:top-[50%] xl:top-[50%] right-[18%] sm:right-[16%] md:right-[15%] lg:right-[16%] xl:right-[16%] transform -translate-y-1/2 banner2-img">
              <img
                src={Banner2}
                // className="w-[60px] sm:w-[120px] md:w-[130px] lg:w-[190px] wiggle-img"
                className="w-[100px] sm:w-[200px] md:w-[250px] lg:w-[350px] wiggle-img"
                alt="Banner 2"
              />
            </div>
            <div className="absolute top-[70%] sm:top-[75%] md:top-[81%] left-[23%] sm:left-[23%] transform -translate-y-1/2 banner3-img">
              <img
                src={Banner3}
                className="w-[100px] sm:w-[200px] md:w-[250px] lg:w-[350px] wiggle-img"
                alt="Banner 3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
