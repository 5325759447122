import React, { useState } from "react";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";
import { LuLogOut } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import SiteLogo from "../../../assets/Group 49.png";
import { VscSymbolProperty } from "react-icons/vsc";
import { FaQ } from "react-icons/fa6";
import { PiNewspaperClippingLight } from "react-icons/pi";

function AdminSidebar({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}) {
  const navigate = useNavigate();

  const sidebarMenu = [
    {
      title: "Manage Customer Reviews",
      icon: <VscSymbolProperty className="h-5 w-5" />,
      link: "/admin/manage_customer_review",
    },
    {
      title: "Manage FAQ",
      icon: <FaQ className="h-5 w-5" />,
      link: "/admin/manage_faq",
    },
  ];

  const handleLogout = () => {
    sessionStorage.removeItem("khadoom-frontend-admin-token");
    navigate("/sign_in");
  };

  const handleMenuClick = (link) => {
    handleToggleSidebar(false);
    navigate(link);
  };

  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      onToggle={handleToggleSidebar}
      breakPoint="lg"
    >
      {/* Header */}
      <SidebarHeader style={{ color: "white", backgroundColor: "#d9dc36" }}>
        <Menu iconShape="circle" style={{ color: "white" }}>
          {collapsed ? (
            <MenuItem
              icon={<FaAngleDoubleRight style={{ color: "white" }} />}
              onClick={handleCollapsedChange}
            ></MenuItem>
          ) : (
            <MenuItem
              suffix={<FaAngleDoubleLeft style={{ color: "white" }} />}
              onClick={handleCollapsedChange}
            >
              <div
                style={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  fontSize: 15,
                  letterSpacing: "1px",
                  color: "white",
                }}
              >
                <img
                  src={SiteLogo}
                  alt="Site logo"
                  className="h-12 w-48 object-contain"
                />
              </div>
            </MenuItem>
          )}
        </Menu>
      </SidebarHeader>
      {/* Content */}
      <SidebarContent style={{ backgroundColor: "#d9dc36", color: "black" }}>
        <Menu iconShape="circle">
          {sidebarMenu.map((item, i) => (
            <MenuItem
              className="font-gotham"
              icon={item.icon}
              key={i}
              onClick={() => handleMenuClick(item.link)}
            >
              {item.title}
            </MenuItem>
          ))}
        </Menu>
      </SidebarContent>

      <SidebarFooter
        style={{
          textAlign: "center",
          backgroundColor: "#d9dc36",
        }}
      >
        <div
          onClick={handleLogout}
          className="sidebar-btn-wrapper font-semibold text-lg"
          style={{
            borderTop: "2px solid #000000",
            padding: "16px",
            color: "#000000",
          }}
        >
          <Link
            className="sidebar-btn font-gotham"
            style={{ cursor: "pointer", color: "#000000" }}
          >
            <LuLogOut className="profile-icon" />
            <p className="ml-3 ">Logout</p>
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
}

export default AdminSidebar;
