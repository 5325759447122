import React, { useEffect, useState } from "react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { IoClose } from "react-icons/io5";
import { Button } from "@material-tailwind/react";
import toast from "react-hot-toast";
import { postServices, putServices } from "../apiServices/apiServices";

function AddFAQ(props) {
  const [addFieldData, setAddFieldData] = useState({
    question: "",
    answer: "",
  });
  const [loading, setLoading] = useState(false);
  const access_token = sessionStorage.getItem("khadoom-frontend-admin-token");
  const maxLength = 300;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "35px",
    outline: "none",
    borderRadius: "15px",
  };

  useEffect(() => {
    setAddFieldData({
      ...addFieldData,
      question: props.fieldData?.question ?? "",
      answer: props.fieldData?.answer ?? "",
    });
  }, [props.fieldData]);

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await postServices(
        "/api/question/add",
        {
          question: addFieldData.question,
          answer: addFieldData.answer,
        },
        access_token
      );
      setLoading(false);
      if (response.responseCode === 200) {
        toast.success("FAQ added...");
        setAddFieldData({
          question: "",
          answer: "",
        });
        props.onClose();
        props.fetchFAQs();
      } else {
        toast.error(response.responseMessage);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await putServices(
        `/api/question/update?question_id=${props.fieldData?.question_id}`,
        {
          question: addFieldData.question,
          answer: addFieldData.answer,
        },
        access_token
      );
      setLoading(false);
      if (response.responseCode === 200) {
        toast.success("FAQ updated..");
        setAddFieldData({
          question: "",
          answer: "",
        });
        props.onClose();
        props.fetchFAQs();
      } else {
        toast.error(response.responseMessage);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <p className="font-semibold text-3xl text-black font-gotham">
                {props.modal?.update_faq
                  ? "Update FAQ"
                  : props.modal?.add_faq
                  ? "Add FAQ"
                  : ""}
              </p>
              <IoClose
                className="cursor-pointer border border-[#f73939] rounded-full absolute right-6 top-8 text-[#f73939]"
                onClick={props.onClose}
              />
            </div>
            <form
              onSubmit={
                props.modal?.update_faq ? handleUpdateSubmit : handleAddSubmit
              }
              className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3 faq-section"
            >
              <input
                className="w-full bg-[#ECF9FF] px-6 py-3 outline-none  font-gotham rounded-full text-black font-light "
                type="text"
                name="question"
                placeholder="Question*"
                value={addFieldData.question}
                onChange={(e) =>
                  setAddFieldData({ ...addFieldData, question: e.target.value })
                }
                required
              />
              <textarea
                className="w-full px-6 py-3 outline-none bg-[#ECF9FF] font-gotham rounded-xl text-black font-light h-40"
                type="text"
                name="answer"
                placeholder="Answer *"
                value={addFieldData.answer}
                onChange={(e) =>
                  setAddFieldData({ ...addFieldData, answer: e.target.value })
                }
                maxLength={maxLength}
                required
              />
              <div className="text-right text-[#8f8f8f] mt-1 font-gotham font-light">
                {addFieldData.answer.length}/{maxLength}
              </div>
              <div className="flex justify-end items-center">
                <Button
                  type="submit"
                  disabled={loading}
                  className=" rounded-full hover:bg-[#E2E301] px-12 py-2 hover:text-[#1818EC] bg-[#1976d2]  text-[#E2E301] tracking-widest font-gotham font-light text-lg outline-none disabled:cursor-not-allowed disabled:bg-gray-600 disabled:text-white"
                >
                  {loading?"Loading..." : "SUBMIT"}
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default AddFAQ;
