import React, { useEffect, useState } from "react";
import HalfName1 from "../../../assets/khadoom-half1.png";
import HalfName2 from "../../../assets/khadoom-half2.png";
import PhoneIcon from "../../../assets/phone-icon.svg";
import { IoLocationOutline } from "react-icons/io5";
import { GoMail } from "react-icons/go";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import { FaPlus } from "react-icons/fa";
import toast from "react-hot-toast";
import { getServices, postServices } from "../../../apiServices/apiServices";
import Faq1Img from "../../../assets/faq1_img.png";
import Faq2Img from "../../../assets/faq2_img.png";
import Faq3Img from "../../../assets/faq3_img.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Icon({ id, open }) {
  return (
    <FaPlus
      className={`${
        id === open ? "rotate-45" : ""
      } h-5 w-5 transition-transform`}
    />
  );
}

function ContactSec() {
  const [open, setOpen] = useState(0);
  const [fieldData, setFieldData] = useState({
    name: "",
    phone: "",
    email: "",
    body: "",
  });
  const [faqsQuestionList, setFaqsQuestionList] = useState([]);

  useEffect(() => {
    fetchFaqQuestionList();

    AOS.init({
      duration: 1200,
      delay: 0, // Customize the animation duration (in milliseconds)
      // once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  useEffect(() => {
    const wiggleEffect = (event) => {
      const images = document.querySelectorAll(".wiggle-img2");

      images.forEach((image) => {
        const rect = image.getBoundingClientRect();
        const moveX = ((event.clientX - rect.left) / rect.width) * 10 - 5;
        const moveY = ((event.clientY - rect.top) / rect.height) * 10 - 5;
        image.style.transform = `translate(${moveX}px, ${moveY}px)`;
      });
    };

    const resetWiggle = () => {
      const images = document.querySelectorAll(".wiggle-img2");
      images.forEach((image) => {
        image.style.transform = "translate(0, 0)";
      });
    };

    document.addEventListener("mousemove", wiggleEffect);
    document.addEventListener("mouseleave", resetWiggle);

    return () => {
      document.removeEventListener("mousemove", wiggleEffect);
      document.removeEventListener("mouseleave", resetWiggle);
    };
  }, []);

  const fetchFaqQuestionList = async () => {
    try {
      const res = await getServices(`/api/question/list`);
      if (res.responseCode === 200) {
        setFaqsQuestionList(res.responseData);
      } else {
        setFaqsQuestionList([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await postServices(`/api/contactform`, {
        name: fieldData.name,
        phone: fieldData.phone,
        body: fieldData.body,
        email: fieldData.email,
      });
      if (res.responseCode === 200) {
        toast.success("Message sent...");
        setFieldData({
          ...fieldData,
          name: "",
          phone: "",
          body: "",
          email: "",
        });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div>
      <div
        className="relative w-full overflow-hidden pt-32 contact-section"
        id="contact-us"
      >
        <img
          src={HalfName1}
          alt=""
          className="pl-10 md:pl-12 lg:pl-16 w-full"
        />

        <div className="max-w-[1300px] mx-auto py-6 md:py-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-0">
            <div
              className="px-5 sm:px-10 md:px-[unset] md:pl-12 lg:pl-20"
              data-aos="fade-right"
            >
              <p className="uppercase text-white font-gotham font-semibold text-[40px]">
                Contact us
              </p>
              <p className="text-white font-gotham font-[50] pt-5 md:pt-8">
              I am Khadoom. Whatever language you speak, I can
              <br className="hidden lg:block" /> understand and whatever task
              you need I can get done!
              <br className="hidden lg:block" /> Contact me on WhatsApp for all
              your daily chores
              <br className="hidden lg:block" />
              and leave the rest up to me.
              </p>

              <div className="pt-10 md:pt-20 flex flex-col gap-4">
                <p className="flex gap-4 leading-[1.4] font-gotham text-white">
                  <IoLocationOutline className="text-[#E2E301] text-xl" />
                  Lusail, Qatar
                </p>
                <p className="flex gap-4 leading-[1.4] font-gotham text-white">
                  <img src={PhoneIcon} alt="" className="w-[22px]" />
                  +974 7444 7766
                </p>
                <p className="flex gap-4 leading-[1.4] font-gotham text-white">
                  <GoMail className="text-[#E2E301] text-xl" />
                  info@khadoom.ai
                </p>
              </div>
            </div>

            <div
              className="px-5 sm:px-10 md:px-[unset] md:pr-12 lg:pr-20"
              data-aos="fade-left"
            >
              <form
                className="flex flex-col gap-5 contact-form"
                onSubmit={handleContactSubmit}
              >
                <input
                  type="text"
                  className="outline-none font-gotham font-light text-white px-6 py-3 rounded-full bg-transparent border-2 border-[#E2E301]"
                  placeholder="Name"
                  value={fieldData.name}
                  onChange={(e) =>
                    setFieldData({ ...fieldData, name: e.target.value })
                  }
                  required
                />
                <input
                  type="number"
                  className="outline-none font-gotham font-light text-white px-6 py-3 rounded-full bg-transparent border-2 border-[#E2E301]"
                  placeholder="Phone"
                  onKeyDown={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "-" ||
                      e.key === "+"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => e.target.blur()}
                  value={fieldData.phone}
                  onChange={(e) =>
                    setFieldData({
                      ...fieldData,
                      phone: e.target.value.slice(0, 10),
                    })
                  }
                  required
                />
                <input
                  type="email"
                  className="outline-none font-gotham font-light text-white px-6 py-3 rounded-full bg-transparent border-2 border-[#E2E301]"
                  placeholder="Email"
                  value={fieldData.email}
                  onChange={(e) =>
                    setFieldData({
                      ...fieldData,
                      email: e.target.value,
                    })
                  }
                  required
                />
                <textarea
                  rows="8"
                  placeholder="Message"
                  className="outline-none font-gotham font-light text-white px-6 py-3 rounded-[25px] bg-transparent border-2 border-[#E2E301]"
                  value={fieldData.body}
                  onChange={(e) =>
                    setFieldData({
                      ...fieldData,
                      body: e.target.value,
                    })
                  }
                  required
                ></textarea>

                <div className="flex justify-end items-center">
                  <button
                    type="submit"
                    className="uppercase font-gotham font-light text-[#E2E301] px-12 py-2 border-2 border-[#E2E301] rounded-full hover:bg-[#E2E301] hover:text-[#1818EC]"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <img
          src={HalfName2}
          alt=""
          className={`pr-10 md:pr-12 lg:pr-16 w-full ${
            faqsQuestionList.length > 0 ? "" : "mb-32"
          }`}
        />

        {faqsQuestionList.length > 0 && (
          <div
            className="max-w-[1300px] mx-auto pt-12 sm:pt-32 pb-40 relative"
            id="faqs"
            data-aos="fade-up"
          >
            <div className="px-2 sm:px-5 2xl:px-[unset]">
              <div className="glassy-bg p-7 sm:p-12 rounded-[30px] w-full md:w-[90%] lg:w-[80%]">
                <p className="uppercase font-gotham font-bold text-white text-[33px] sm:text-[45px] md:text-[60px] lg:text-[70px] xl:text-[90px] leading-[1]">
                  FREQUENTLY
                  <br /> ASKED QUESTIONS
                </p>

                <div className="mb-5 mt-12 border-t-2">
                  {faqsQuestionList.map((item, i) => (
                    <Accordion
                      key={i}
                      open={open === i + 1}
                      icon={<Icon id={i + 1} open={open} />}
                      className="border-b-2"
                    >
                      <AccordionHeader
                        onClick={() => handleOpen(i + 1)}
                        className="font-gotham font-light text-white text-[17px]"
                      >
                        {item.question ?? "-"}
                      </AccordionHeader>
                      <AccordionBody className="font-gotham font-light text-white">
                        {item.answer ?? "-"}
                      </AccordionBody>
                    </Accordion>
                  ))}
                </div>
              </div>
            </div>

            <img
              src={Faq1Img}
              alt=""
              className="hidden md:block absolute top-4 -right-[10px] w-[200px] lg:w-[260px] wiggle-img"
            />
            <img
              src={Faq2Img}
              alt=""
              className="hidden md:block absolute top-[42%] lg:top-[40%] right-[0px] lg:right-[100px] w-[140px] lg:w-[200px] wiggle-img"
            />
            <img
              src={Faq3Img}
              alt=""
              className="hidden md:block absolute top-[65%] lg:top-[60%] -right-[50px] lg:-right-[120px] w-[150px] lg:w-[230px] wiggle-img"
              style={{ rotate: "25deg" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactSec;
