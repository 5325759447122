import toast from "react-hot-toast";
import config from "../config.json";
import axios from "axios";

const baseUrl = config.url;

const postServices = async (url, data, access_token) => {
  try {
    const res = await axios.post(`${baseUrl}${url}`, data, {
      headers: { Authorization: access_token },
    });
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

const getServices = async (url, access_token) => {
  try {
    const res = await axios.get(`${baseUrl}${url}`, {
      headers: { Authorization: access_token },
    });
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

const putServices = async (url, data, access_token) => {
  try {
    const res = await axios.put(`${baseUrl}${url}`, data, {
      headers: { Authorization: access_token },
    });
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

const deleteServices = async (url, access_token, body) => {
  try {
    const res = await axios.delete(`${baseUrl}${url}`, {
      headers: { Authorization: access_token },
      data: body,
    });
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export { postServices, getServices, putServices, deleteServices };
