import React, { useState, useEffect, useRef } from "react";
import { SlMenu, SlSocialLinkedin } from "react-icons/sl";
import { MdClose } from "react-icons/md";
import Bluelogo from "../../../assets/Group 49.png";
// import Facebook from "../../../assets/fb.png";
import { IoLogoInstagram } from "react-icons/io";
// import { RxTwitterLogo } from "react-icons/rx";
// import { LiaYoutube } from "react-icons/lia";
import { FaWhatsapp } from "react-icons/fa";
import { RiTiktokLine } from "react-icons/ri";

function HeaderNavbar(props) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <>
      <div className="relative w-full z-50">
        <div className="fixed top-6 md:top-7 right-5 md:right-10">
          <button onClick={handleClick} className="focus:outline-none">
            <SlMenu size={24} className="text-white" />
          </button>
        </div>

        <div
          ref={menuRef}
          className={`fixed inset-0 z-50 transition-all duration-300 ease-in-out ${
            open ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-full"
          }`}
          style={{
            filter: "drop-shadow(0 0px 40px rgba(0, 0, 0, 0.5))",
          }}
        >
          <div
            className="bg-[#e6e6ea] w-full rounded-b-3xl shadow-lg p-3 md:p-4"
            style={{
              filter: "drop-shadow(0 0px 40px rgba(0, 0, 0, 0.5))",
            }}
          >
            <div className="flex justify-between items-center w-full px-0 md:px-4 py-6 md:py-1">
              <div>
                <img
                  src={Bluelogo}
                  alt="Khadoom"
                  className="w-auto h-6 md:h-9"
                />
              </div>
              <div className="flex items-center gap-4 pr-2">
                <div className="flex gap-3 md:gap-4 lg:gap-6">
                  {/* <img
                    src={Facebook}
                    alt="Facebook"
                    className="w-5 h-5 md:w-6 md:h-6 lg:w-7 lg:h-7"
                  /> */}
                  <IoLogoInstagram
                    className="text-xl md:text-2xl lg:text-3xl cursor-pointer"
                    onClick={() =>
                      window.open("https://www.instagram.com/khadoom.ai/")
                    }
                  />
                  {/* <RxTwitterLogo className="text-xl md:text-2xl lg:text-3xl" />
                  <LiaYoutube className="text-xl md:text-2xl lg:text-3xl" /> */}
                  <SlSocialLinkedin className="text-xl md:text-2xl lg:text-3xl cursor-pointer" />
                  <RiTiktokLine
                    className="text-xl md:text-2xl lg:text-3xl cursor-pointer"
                    onClick={() =>
                      window.open("https://www.tiktok.com/@khadoom.ai")
                    }
                  />
                </div>
                <button onClick={handleClick}>
                  <MdClose
                    size={34}
                    className=" text-[#0000EC] hover:text-gray-700 text-xl md:text-2xl lg:text-3xl"
                  />
                </button>
              </div>
            </div>

            <ul className="flex flex-col md:flex-row justify-center gap-4 md:gap-7 h-full items-center pt-16 pb-20 uppercase font-gotham font-light text-sm md:text-base lg:text-lg">
              <li
                className="hover:text-[#0000EC] hover:font-medium cursor-pointer transition-all"
                onClick={() => {
                  window.location.href = "#about-us";
                  setOpen(false);
                }}
              >
                About me
              </li>
              <li
                className="hover:text-[#0000EC] hover:font-medium cursor-pointer transition-all"
                onClick={() => {
                  window.location.href = "#features";
                  setOpen(false);
                }}
              >
                Features
              </li>
              <li
                className="hover:text-[#0000EC] hover:font-medium cursor-pointer transition-all"
                onClick={() => {
                  window.location.href = "#customer-reviews";
                  setOpen(false);
                }}
              >
                Customer reviews
              </li>
              <li
                className="hover:text-[#0000EC] hover:font-medium cursor-pointer transition-all"
                onClick={() => {
                  window.location.href = "#contact-us";
                  setOpen(false);
                }}
              >
                Contact us
              </li>
              <li
                className="hover:text-[#0000EC] hover:font-medium cursor-pointer transition-all"
                onClick={() => {
                  window.location.href = "#faqs";
                  setOpen(false);
                }}
              >
                Faqs
              </li>
              <li className="hover:text-[#0000EC] hover:font-medium cursor-pointer transition-all">
                Blog
              </li>
            </ul>
          </div>
        </div>

        <div
          className="fixed bottom-4 right-4 border-4 border-white rounded-full cursor-pointer whatsapp-icon"
          onClick={() => window.open("http://wa.me/97474447766")}
        >
          <span className="font-gotham font-semibold uppercase text-white text-xl">
            Chat with me
          </span>
          <FaWhatsapp className="text-4xl text-white" />
          <div className="absolute -top-3 right-[35px] transform border-t-0 border-b-[10px] border-l-[15px] border-b-white border-l-transparent"></div>
        </div>
      </div>

      {props.children}
    </>
  );
}

export default HeaderNavbar;
